<script>
  // for reference: https://my.onetrust.com/s/article/UUID-7478d3b4-18eb-3ac0-a6fd-fb7ebff9f8dc
  const scriptName = import.meta.env.VITE_ONE_TRUST_SCRIPT_NAME;
</script>

<svelte:head>
  {#if scriptName}
    <!-- OptanonConsentNoticeStart -->
    <script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script={scriptName}
    ></script>
    <script type="text/javascript">
      function OptanonWrapper() {
        const oneTrustLoadedEvent = new CustomEvent("onetrustloaded");
        window.dispatchEvent(oneTrustLoadedEvent);
        var vwoConsentProvided = OnetrustActiveGroups.includes("C0002");
        window.VWO = window.VWO || [];
        window.VWO.init =
          window.VWO.init ||
          function (state) {
            window.VWO.consentState = state;
          };
        if (OneTrust.IsAlertBoxClosedAndValid()) {
          VWO.init(vwoConsentProvided ? 1 : 3);
        } else {
          VWO.init(2);
        }
      }
    </script>
    <!-- OptanonConsentNoticeEnd -->
  {/if}
</svelte:head>
