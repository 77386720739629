export function setCookie(cookieName, value, daysToExpire = 180) {
  let date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${value}; path=/; expires=${date.toUTCString()} `;
}

export function getCookie(name) {
  if (document) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }
}

/**
 * Checks the global OnetrustActiveGroups variable for the presence of
 * performance cookie consent, denoted by category ID "C0002"
 * @returns {boolean}
 */
export function acceptingPerformanceCookies() {
  return getCookie("_of") === "accept" || window.OnetrustActiveGroups?.includes("C0002");
}

export function getAllCookies() {
  const cookies = document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});

  return cookies;
}

export function deleteCookie(cookieName) {
  //Cookies don't use the subdomain in the name, so we only want to include up to two pieces
  // (This also works for localhost)
  const domainName = location.hostname.split(".").slice(-2).join(".");
  document.cookie = `${cookieName}=; expires=${new Date(
    0
  ).toUTCString()};path=/; domain=${domainName}`;
}

/**
 * Extracts the user ID from the Google Analytics device ID.
 * @example `GA1.1.xxxxxxxxxx.xxxxxxxxxx => xxxxxxxxxx_xxxxxxxxxx`
 * @link https://support.google.com/analytics/answer/11397207
 */
function extractGoogleAnalyticsUserIdFromCookie(gaCookie) {
  if (gaCookie) {
    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split(".").slice(-2);
    if (userIdParts.length === 2) {
      return userIdParts.join("_");
    }
  }
  return undefined;
}

function getBrowserCookie(cookieName) {
  // In React Native environments, `document.cookie` doesn't exist.
  if (typeof document !== "object" || typeof document.cookie !== "string") {
    return undefined;
  }
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

/**
 * Returns the Google Analytics User ID from a browser cookie name.
 * @example `getGoogleAnalyticsUserIdFromBrowserCookie('_ga')`
 */
export function getGoogleAnalyticsUserIdFromBrowserCookie(cookieName) {
  const browserCookie = getBrowserCookie(cookieName);

  if (!browserCookie) {
    return undefined;
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie);
}
