import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { acceptingPerformanceCookies } from "../cookies";

const SITE = "us3.datadoghq.com";
const SERVICE = "obamaorg-swa";
const ENVIRONMENT = import.meta.env.VITE_DATADOG_ENVIRONMENT || "local";

let conditionalSampleRate;

export const clientIsBot = (userAgent) => {
  let isBot = false;

  /**
   * This code to filter out bots was provided by the Datadog documentation: https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/#filter-out-bot-sessions-on-intake
   * I added "Electron" to filter out page hits from our Cypress tests.
   */
  let botPattern =
    // eslint-disable-next-line regexp/no-unused-capturing-group, regexp/no-dupe-disjunctions
    "(googlebot/|bot|Electron|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";

  let regex = new RegExp(botPattern, "i");

  isBot = regex.test(userAgent);

  // set var conditionalSampleRate to 0 if the userAgent matches a pattern in botPatterns
  // otherwise, conditionalSampleRate is the value of the env variable
  conditionalSampleRate = isBot ? 0 : parseInt(import.meta.env.VITE_DATADOG_SAMPLE_RATE) || 0;

  return isBot;
};

export const initDatadogMonitoring = () => {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: SITE,
    version: import.meta.env.VITE_DATADOG_RELEASE || "test",
    service: SERVICE,
    env: ENVIRONMENT,
    sessionReplaySampleRate: 0,
    sessionSampleRate: conditionalSampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackingConsent: "not-granted",
    /**
     * Modes, from most restrictive to least restrictive, are: "mask", "mask-user-input", and "allow".
     *
     * For more detailed information, see: https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/
     */
    defaultPrivacyLevel: "mask"
  });
};

export const initDatadogLogs = () =>
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: SITE,
    version: import.meta.env.VITE_DATADOG_RELEASE || "test",
    service: SERVICE,
    env: ENVIRONMENT,
    forwardErrorsToLogs: true,
    sessionSampleRate: conditionalSampleRate,
    trackingConsent: acceptingPerformanceCookies() ? "granted" : "not-granted"
  });
